import { createTheme } from '@material-ui/core/styles';

export const colorPalette = {
  primary: '#013C4D',
  secondary: '#2B6879',
  white: '#FFFFFF',
  blue: '#4299E1',
  red: '#F15656',
  yellow: '#FFC107',
  gray: '#9A9C9F',
  graySecondary: '#8B8C8F',
  lightgray: '#F6F6F6',
  gray1: '#E0E0E0',
  gray2: '#4F4F4F',
  gray3: '#8B8C8F',
  lightBluePrimary: '#EFF3F7',
  transparent: 'transparent',
};

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: colorPalette.primary,
    },
    secondary: {
      main: colorPalette.secondary,
    },
  },
  typography: {
    fontFamily: 'Inter',
    fontSize: 14,
    button: {
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
      color: colorPalette.gray2,
    },
    body2: {
      fontSize: 12,
      lineHeight: '14.4px',
      color: colorPalette.gray2,
    },
    h2: {
      fontSize: 28,
      color: colorPalette.secondary,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      lineHeight: '30px',
      color: colorPalette.primary,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid' + colorPalette.gray1,
        padding: '0 10px',
        borderRadius: 4,
        height: 44,
        backgroundColor: colorPalette.white,
        '&:hover': {
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        },
        '&:pressed': {
          backgroundColor: colorPalette.lightBluePrimary,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '0px',
        },
        '&:after': {
          borderBottom: '0px',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '0px',
        },
      },
    },
    MuiSelect: {
      icon: {
        right: '8px',
      },
      selectMenu: {
        color: colorPalette.gray3,
        paddingLeft: 5,
      },
    },
    MuiInputLabel: {
      formControl: {
        top: '-5px',
        color: colorPalette.gray2,
        fontWeight: 500,
      },
    },
    MuiButton: {
      sizeSmall: {
        padding: '6px 16px',
        fontSize: '14px',
      },
      sizeLarge: {
        padding: '9px 24px',
        fontSize: '16px',
      },
      contained: {
        backgroundColor: colorPalette.primary,
        color: colorPalette.white,
        '&:hover': {
          backgroundColor: colorPalette.secondary,
        },
        '&:active': {
          backgroundColor: colorPalette.secondary,
        },
      },
      outlined: {
        color: colorPalette.primary,
        borderColor: colorPalette.primary,
        '&:hover': {
          color: colorPalette.secondary,
          borderColor: colorPalette.secondary,
          backgroundColor: colorPalette.white,
        },
        '&:active': {
          color: colorPalette.secondary,
          borderColor: colorPalette.secondary,
          backgroundColor: colorPalette.white,
        },
      },
      text: {
        color: colorPalette.primary,
        backgroundColor: colorPalette.white,
        '&:hover': {
          color: colorPalette.secondary,
          backgroundColor: colorPalette.transparent,
        },
        '&:active': {
          color: colorPalette.secondary,
          backgroundColor: colorPalette.transparent,
        },
      },
      textSecondary: {
        '&:hover': {
          color: colorPalette.secondary,
          backgroundColor: colorPalette.transparent,
        },
      },
    },
  },
});
