import { matchingQueryReducer } from 'store/modules/matching/matching.query';
import { opportunitySliceReducer } from 'store/modules/matching/matching.slice';

const combinedReducer = {
  ...matchingQueryReducer,
  ...opportunitySliceReducer,
};

export * from 'store/modules/matching/matching.query';
export * from 'store/modules/matching/matching.slice';
export default combinedReducer;
