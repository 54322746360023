import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReduxMatching } from 'store/modules/matching/matching';

const reducerName = 'opportunity';

export const initialState: IReduxMatching.opportunity = {
  displayText: '',
  status: '',
  structure: '',
  contentItemId: '',
  summary: '',
  division: {
    displayText: '',
    contentItemId: '',
  },
};

export const opportunitySlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    changeValue(state, action: PayloadAction<IReduxMatching.opportunity>) {
      state.displayText = action.payload.displayText;
      state.status = action.payload.status;
      state.structure = action.payload.structure;
      state.contentItemId = action.payload.contentItemId;
      state.division = action.payload.division;
      state.summary = action.payload.summary;
    },
  },
});

export const { changeValue } = opportunitySlice.actions;
export const opportunitySliceReducer = {
  [reducerName]: opportunitySlice.reducer,
};
