import { createApi } from '@reduxjs/toolkit/query/react';

import http from 'utils/http';
import { IReduxMatching } from 'store/modules/matching/matching';

const reducerPath = 'matchingApi';
export const matchingAPI = createApi({
  reducerPath,
  baseQuery: http(),
  endpoints: builder => ({
    getFormJsonSchema: builder.query({
      query: () => ({
        url: `/matching/form-json-schema/`,
        method: 'GET',
      }),
      transformResponse: (
        response: IReduxMatching.IFormJsonSchemaResponse,
      ): IReduxMatching.IFormJsonSchemaTransactionFormTransformed => {
        const mainObject =
          response?.definitions?.TransactionFormJsonSchema ?? {};
        let movedProperties = {};
        let newProperties = {};

        const propertyKeys = Object.keys(mainObject?.properties ?? {});
        propertyKeys.forEach(propertyKey => {
          const property = mainObject?.properties?.[propertyKey];
          if (property?.['ui:widget']) {
            newProperties = {
              ...newProperties,
              [propertyKey]: {
                ...property,
                inputName: propertyKey,
              },
            };
          } else {
            movedProperties = {
              ...movedProperties,
              [propertyKey]: property,
            };
          }
        });

        return {
          ...mainObject,
          properties: newProperties,
          movedProperties,
        };
      },
    }),
    getInvestors: builder.mutation({
      query: (body: IReduxMatching.IGetInvestorsRequestBody) => ({
        url: `/matching/match/`,
        method: 'POST',
        body,
      }),
      transformResponse: (
        response: IReduxMatching.ITeamsResponse,
      ): IReduxMatching.ITeamsResponse => {
        return response as IReduxMatching.ITeamsResponse;
      },
    }),
    getParameters: builder.query({
      query: (id: string) => ({
        url: `/matching/parameters/?id=${id}`,
        method: 'GET',
      }),
      transformResponse: (
        response: IReduxMatching.IParameterResponse,
      ): IReduxMatching.IParameterResponse => {
        console.log('response', response);
        return response as IReduxMatching.IParameterResponse;
      },
    }),
    getOrganizations: builder.query({
      query: () => ({
        url: `/matching/organizations/`,
        method: 'GET',
      }),
    }),
    getTeamsByOrganizationID: builder.query({
      query: (id: string) => ({
        url: `/matching/teams/organizations/${id}`,
        method: 'GET',
      }),
    }),
    getOpportunities: builder.query({
      query: () => ({
        url: `/platform-data/filtered-opportunities/`,
        method: 'GET',
      }),
      transformResponse: (
        response: IReduxMatching.IGetOpportunitiesResponse,
      ): IReduxMatching.opportunity[] => {
        return response?.data ?? [];
      },
    }),
  }),
});

export const {
  useGetParametersQuery,
  useGetInvestorsMutation,
  useGetOpportunitiesQuery,
  useGetOrganizationsQuery,
  useGetFormJsonSchemaQuery,
  useGetTeamsByOrganizationIDQuery,
} = matchingAPI;

export const matchingQueryReducer = { [reducerPath]: matchingAPI.reducer };
export const matchingSelector = state => state[reducerPath];
