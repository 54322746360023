import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { combinedReducer, combinedMiddleware } from 'store/modules';

let store;
if (typeof window !== 'undefined') {
  const storage = require('redux-persist/lib/storage').default;
  const persistConfig = {
    key: '}r()3^bNUL5x{M4]iKmgG3n:/z~P7ntR0u;]1vWI!qXN78%gD?b#MyyLvY"Q|fL',
    version: 1,
    whitelist: ['general', 'user'],
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, combinedReducer); // Create a new reducer with our existing reducer

  store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(combinedMiddleware),
  });

  store.__persistor = persistStore(store); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature
} else {
  store = configureStore({
    reducer: combinedReducer,
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware().concat(combinedMiddleware),
  });
}

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
