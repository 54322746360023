import { createSlice } from '@reduxjs/toolkit';

import { IReduxUser } from 'store/modules/user/user';
import { connectAPI } from '../connect';

const reducerName = 'user';

export const initialState: IReduxUser.IInitialState = {
  access_token: '',
  refresh_token: '',
  token_type: '',
  email: '',
  name: '',
  expires_in: 0,
};

export const userSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setUser: (
      state: IReduxUser.IInitialState,
      { payload }: { payload: IReduxUser.IInitialState },
    ) => {
      state.email = payload?.email ?? '';
      state.name = payload?.name || payload?.email;
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.token_type = payload.token_type;
    },
    logout: (state: IReduxUser.IInitialState) => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key] || '';
      });
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      connectAPI.endpoints.refreshToken.matchFulfilled,
      (state, { payload }) => {
        state.email = payload?.email ?? '';
        state.name = payload?.name || payload?.email;
        state.refresh_token = payload.refresh_token;
        state.access_token = payload.access_token;
        state.expires_in = payload.expires_in;
        state.token_type = payload.token_type;
        state.scope = payload?.scope ?? '';
      },
    );
    builder.addMatcher(
      connectAPI.endpoints.getTokenByCredential.matchFulfilled,
      (state, { payload }) => {
        state.email = payload?.email ?? '';
        state.name = payload?.name || payload?.email;
        state.refresh_token = payload.refresh_token;
        state.access_token = payload.access_token;
        state.expires_in = payload.expires_in;
        state.token_type = payload.token_type;
        state.scope = payload?.scope ?? '';
      },
    );
    builder.addMatcher(connectAPI.endpoints.logout.matchFulfilled, state => {
      state.access_token = initialState.access_token;
      state.name = initialState.name;
      state.email = initialState.email;
    });
    builder.addMatcher(
      connectAPI.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.email = payload?.email ?? '';
        state.name = payload?.name || payload?.email;
      },
    );
  },
});

export const { setUser, logout } = userSlice.actions;
export const userSliceReducer = { [reducerName]: userSlice.reducer };
