import { createSlice } from '@reduxjs/toolkit';

import { IReduxGeneral } from 'store/modules/general/general';

const reducerName = 'general';

export const initialState: IReduxGeneral.IInitialState = {
  isLoading: false,
};

export const generalSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setIsLoading: (state: IReduxGeneral.IInitialState, { payload = false }) => {
      state.isLoading = payload;
    },
  },
});

export const { setIsLoading } = generalSlice.actions;
export const generalSliceReducer = { [reducerName]: generalSlice.reducer };
