import 'isomorphic-unfetch';
import { fetchBaseQuery, BaseQueryFn } from '@reduxjs/toolkit/query/react';

import config from 'config';

export const getToken = (state: any) => {
  const { user } = state;
  const token = user?.access_token ?? '';
  const tokenType = user?.token_type ?? '';

  if (!token || !tokenType) {
    return null;
  }

  return `${tokenType} ${token}`;
};

export const http = (
  isServer: any = undefined,
  additionalHeader = {},
  baseUrl = config.API_URL,
): BaseQueryFn<any> => {
  if (typeof isServer === 'undefined') {
    isServer = typeof window === 'undefined';
  }

  const additionalHeaderKeys = Object.keys(additionalHeader);

  if (isServer) {
    return async ({ url, method, body, headers }, { dispatch, getState }) => {
      let newHeaders = {};
      const token: any = getToken(getState());

      if (token) {
        newHeaders = {
          Authorization: token,
        };
      }

      newHeaders = {
        ...newHeaders,
        ...headers,
        ...additionalHeader,
      };

      let formattedBody: any = JSON.stringify(body);

      if (
        newHeaders?.['Content-Type'] === 'application/x-www-form-urlencoded'
      ) {
        formattedBody = new URLSearchParams(body);
      }

      try {
        const result = await fetch(`${baseUrl}${url}`, {
          method,
          headers: newHeaders,
          body: formattedBody,
        })
          .then(res => res.json())
          .then(res => res);
        return { data: result };
      } catch (err: any) {
        return {
          error: {
            status: err?.response?.status,
            data: err?.response?.data,
          },
        };
      }
    };
  } else {
    return fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers, { getState }: any) => {
        const token: any = getToken(getState());

        if (token) {
          headers.set('Authorization', token);
        }

        if (additionalHeaderKeys?.length) {
          additionalHeaderKeys.forEach(key => {
            headers.set(key, additionalHeader[key]);
          });
        }
        return headers;
      },
    });
  }
};

export default http;
