import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { LoginPage } from './pages/LoginPage/Loadable';
import { MatchingPage } from './pages/MatchingPage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { ResetPasswordPage } from './pages/ResetPasswordPage/Loadable';

export function App() {
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Finitive"
        defaultTitle="Finitive"
        htmlAttributes={{ lang: 'en' }}
      >
        <meta name="description" content="A Finitive application" />
      </Helmet>

      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/matching" component={MatchingPage} />
        <Route exact path="/forgot-password" component={ResetPasswordPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
