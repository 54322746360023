import { combineReducers, MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import matchReducer, { matchingAPI } from 'store/modules/matching';
import generalReducer from 'store/modules/general';
import userReducer, { userSlice } from 'store/modules/user';
import connectReducer, { connectAPI } from 'store/modules/connect';

export const combinedReducer = combineReducers({
  ...generalReducer,
  ...matchReducer,
  ...userReducer,
  ...connectReducer,
});

const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => async action => {
    const message = action?.error?.message;
    if (
      (message === 'Unexpected token < in JSON at position 0' ||
        message === 'Failed to fetch') &&
      action?.meta?.requestStatus === 'rejected'
    ) {
      const refreshToken = api.getState()?.user?.refresh_token ?? '';
      if (refreshToken) {
        const { error, data } = await api.dispatch<any>(
          connectAPI.endpoints.refreshToken.initiate(refreshToken),
        );
        if (
          typeof error === 'undefined' ||
          typeof data?.refresh_token === 'undefined'
        ) {
          api.dispatch(userSlice.actions.logout());
        } else {
          window?.location?.reload();
        }
      } else {
        api.dispatch(userSlice.actions.logout());
      }
    }

    return next(action);
  };

export const combinedMiddleware = [matchingAPI.middleware, rtkQueryErrorLogger];
