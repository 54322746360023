import { createApi } from '@reduxjs/toolkit/query/react';

import http from 'utils/http';
import { IReduxConnect } from 'store/modules/connect/connect';
import config from 'config';

const reducerPath = 'connectApi';
export const connectAPI = createApi({
  reducerPath,
  baseQuery: http(
    true,
    {
      'Content-Type': 'application/json',
    },
    config.AUTH_URL,
  ),
  endpoints: build => ({
    getUserInfo: build.mutation({
      query: param => ({
        url: `/user`,
        method: 'GET',
        params: param,
      }),
      transformResponse: (
        response: IReduxConnect.IGetTokenByCredentialResponse,
      ): IReduxConnect.IGetTokenByCredentialResponse => {
        return response as IReduxConnect.IGetTokenByCredentialResponse;
      },
    }),
    getTokenByCredential: build.mutation({
      query: (body: IReduxConnect.IGetTokenByCredentialParams) => ({
        url: `/login`,
        method: 'POST',
        body: {
          client_id: config.CLIENT_ID,
          username: body.username,
          password: body.password,
        },
      }),
      transformResponse: (
        response: IReduxConnect.IGetTokenByCredentialResponse,
      ): IReduxConnect.IGetTokenByCredentialResponse => {
        return response as IReduxConnect.IGetTokenByCredentialResponse;
      },
    }),
    refreshToken: build.mutation({
      query: (refresh_token: string) => ({
        url: `/refresh-token`,
        method: 'POST',
        body: {
          client_id: config.CLIENT_ID,
          refresh_token: refresh_token,
        },
      }),
      transformResponse: (
        response: IReduxConnect.IGetTokenByCredentialResponse,
      ): IReduxConnect.IGetTokenByCredentialResponse => {
        return response as IReduxConnect.IGetTokenByCredentialResponse;
      },
    }),
    logout: build.mutation({
      query: () => ({
        url: `/logout`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetTokenByCredentialMutation,
  useLogoutMutation,
  useGetUserInfoMutation,
  useRefreshTokenMutation,
} = connectAPI;

export const connectQueryReducer = { [reducerPath]: connectAPI.reducer };
export const querySelector = state => state[reducerPath];
